<template>
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=YourFontName&display=swap"
  />
  <router-view />
</template>

<script setup lang="ts"></script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,700;1,500&family=JetBrains+Mono:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,700;1,500&family=JetBrains+Mono:wght@800&family=Rosario:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
</style>
