import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/oasis_logo_1.png'


const _hoisted_1 = { class: "fixed top-0 z-10 container mx-auto bg-white nav" }
const _hoisted_2 = { class: "flex items-center justify-between px-4 md:mx-4 h-16" }
const _hoisted_3 = { class: "flex space-x-2 md:space-x-6 items-center text-white" }
const _hoisted_4 = { class: "border border-solid border-white border-1 md:border-2 rounded-lg py-0 px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "flex items-center space-x-4"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              class: "w-10 px-0 rounded-md"
            }, null, -1),
            _createElementVNode("div", { class: "hidden md:inline md:text-2xl text-red-500 font-bold font-cormorant-garamond" }, " Morgan Oasis Nails ", -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "",
          "active-class": "active",
          to: "/"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Home")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "",
          "active-class": "active",
          to: "/services"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Services")
          ])),
          _: 1
        }),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "border border-solid border-white border-1 md:border-2 rounded-lg py-0 px-2" }, [
          _createElementVNode("a", { href: "tel:(405) 265-2494" }, " Call us ")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/booking" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Book now")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}