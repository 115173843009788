<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class NavBar extends Vue {}
</script>

<template>
    <div class="fixed top-0 z-10 container mx-auto bg-white nav">
      <div class="flex items-center justify-between px-4 md:mx-4 h-16">
        <!-- Logo -->
        <div>
          <router-link to="/" class="flex items-center space-x-4">
            <img src="../assets/oasis_logo_1.png" alt="" class="w-10 px-0 rounded-md" />
            <div class="hidden md:inline md:text-2xl text-red-500 font-bold font-cormorant-garamond">
              Morgan Oasis Nails
            </div>
          </router-link>
        </div>
        <!-- Menu -->
        <div class="flex space-x-2 md:space-x-6 items-center text-white">
          <router-link class="" active-class="active" to="/">Home</router-link>
          <router-link class="" active-class="active" to="/services">Services</router-link>
          <!--          <router-link class="text-white font-semibold" to="/booking"-->
          <!--            >Booking</router-link-->
          <!--          >-->
          <div class="border border-solid border-white border-1 md:border-2 rounded-lg py-0 px-2">
            <a href="tel:(405) 265-2494"> Call us </a>
          </div>
          <div class="border border-solid border-white border-1 md:border-2 rounded-lg py-0 px-2">
            <router-link to="/booking">Book now</router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
.nav {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.active {
  border-bottom: 2px solid rgba(255, 255, 255, 1);
  //font-weight: bold;
}
</style>
